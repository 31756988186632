import React, { Component } from "react";
import SEO from "../components/seo";
import DefaultLayout from "../layout";
import Form from '../components/forms/form'

import "../assets/styles/fonts.css";

class Home extends Component {
  render() {
    return (
      <DefaultLayout>
        <SEO
          title="The Morning Gazette"
          description="An online archive of The Morning Gazette newspaper" 
        />
        <div id="site-content__inner">
          <Form />
        </div>
      </DefaultLayout>
    );
  }
}
export default Home;